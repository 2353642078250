#UserAgent
_ua = do ->
  IE = document.uniqueID
  ltIE6 = typeof window.addEventListener is undefined and typeof document.documentElement.style.maxHeight is undefined
  ltIE7 = typeof window.addEventListener is undefined and typeof document.querySelectorAll is undefined
  ltIE8 = typeof window.addEventListener is undefined and typeof document.getElementsByClassName is undefined
  ltIE9 = IE and typeof window.Worker is undefined
  IE6 = IE and ltIE6
  IE7 = IE and ltIE7 and !ltIE6
  IE8 = IE and ltIE8 and !ltIE7 and !ltIE6
  IE9 = IE and ltIE9 and !ltIE8 and !ltIE7 and !ltIE6
  IE10 = IE and !ltIE9 and !ltIE8 and !ltIE7 and !ltIE6
  Webkit = !document.uniqueID and !window.opera and !window.sidebar and !window.orientation and window.localStorage
  Safari = Webkit and navigator.vendor.search(/apple/i) isnt -1
  Chrome = Webkit and navigator.vendor.search(/google/i) isnt -1

  return {
    IE:IE,
    ltIE6:ltIE6,
    ltIE7:ltIE7,
    ltIE8:ltIE8,
    ltIE9:ltIE9,
    IE6:IE6,
    IE7:IE7,
    IE8:IE8,
    IE9:IE9,
    IE10:IE10,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit:Webkit,
    Safari:Safari,
    Chrome:Chrome,
    Mobile:window.orientation
  }

#URL
url = do ->
  href = location.href.split '/'

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  workRegex = /^.*\/pc\/[^\/]+\/.*$/

  for val , i in href
    if val == '' or i == href.length - 1 and val.indexOf '.'
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    length = 3

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j == 0
      path += '//'

    else
      path += '/'

  return path

#スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing,
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
)

$ ->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

  # フッター高さ取得
  footerHeight = -># {{{
    add = 0
    height = $('#footer').outerHeight()
    $('#content').css('padding-bottom',height+add)
    $('#footer').css('height',height)
    return

  footerHeight()

  $(window).on('load, resize'
    ->
      footerHeight()
      return
  )
  # }}}

  # アンカーリンク
  $('.sidebar a[href^=#]').click(#{{{
    (e)->
      $(
        if (navigator.userAgent.indexOf('Opera') isnt -1)
        then (
          if document.compatMode is 'BackCompat'
          then 'body'
          else 'html'
        )
        else 'html,body'
      ).animate(
        scrollTop:$($(this).attr('href')).offset().top - 20
      ,
        easing:'easeInOutCirc',
        duration:1000
      )

      e.preventDefault()
      return
  )#}}}

  # ヘッダーサブメニュー
  $('#gnavi .nav-item.pain,#gnavi .nav-item.sportsinjury,#gnavi .nav-item.contents,#gnavi .nav-item.price').on('mouseenter'#{{{
    ->
      $(this).addClass('is-hover')
  ).on('mouseleave'
    ->
      $(this).removeClass('is-hover')
  )#}}}



  # 左メニュー開閉
  $('#sub .menu-item .title').on('click'
    (e)->
      $(this).closest('.menu-item').toggleClass('is-open')
      $(this).closest('.menu-item').find('.page-list').slideToggle(150)
      e.stopPropagation()
  )

  $('#sub .menu-item .title a').on('click'
    (e)->
      e.stopPropagation()
  )

  # フッタースライド
  $('#f-slide').slidesjs(
    width:290
    height:166
    navigation:
      active:true
      effect:"fade"
    play:
      effect:"fade"
      auto:true
    pagination:
      active:false
  )

 # Q&A開閉
  $('#main .qa-box .q-title').on('click'
    (e)->
      $(this).closest('.qa-inner').toggleClass('is-open')
      $(this).closest('.qa-inner').find('.answer-box').slideToggle(150)
      e.stopPropagation()
  )

  $('#main .qa-box .q-title').on('click'
    (e)->
      e.stopPropagation()
  )

  #フローティングメニュー
  if $id isnt "confirmation"
    nav = $("#gnavi")
    navTop = nav.offset().top + 120
    navHeight = nav.height() + 10
    showFlag = false
    nav.css "top", -navHeight + "px"

    #ナビゲーションの位置まできたら表示
    $(window).scroll ->
      winTop = $(this).scrollTop()
      if winTop >= navTop
        if showFlag is false
          showFlag = true
          nav.addClass("fixed").stop().animate
            top: "0px"
          , 500
      else if winTop <= navTop
        if showFlag
          showFlag = false
          nav.stop().animate
            top: -navHeight + "px"
          , 200, ->
            nav.removeClass "fixed"
            return

      return

  # 右下リンク
  rightNav = $(".sidebar")
  rightNav.hide()
  $(window).scroll ->
    if $(this).scrollTop() > 200
      rightNav.fadeIn()
      $(".sidebar").css
        position: "fixed"
        right: "-1px"
        bottom: "10px"
        zIndex: "1000"

    else
      rightNav.fadeOut()
    return

  $(".s-contents.firstone a,.home .top_category dl dt a,.home .contents .contents-list .contents-item a,.contents .front .contents-list .contents-item a,.symptoms .wrapper .list .list-item a,.symptoms .wrapper .list .list-item a:not('.link-btn'),.pain .our-treatment .list a,.sportsinjury .our-treatment .list a").addClass "wink"
  $(".wink").mouseover ->
    $(this).css opacity: "0.2"
    $(this).css "filter", "alpha(opacity=0.2)"
    $(this).fadeTo 600, 1.0
    return

  # 固定コンテンツ制御
  fixedContents =
    ()->
      mainHeight = $('#main').height()
      subHeight = $('#sub').height()

      if subHeight < mainHeight
        $('#sub').css('min-height', $('#main').outerHeight(true))

        mainContentsHeight = $('#main').outerHeight(true)
        subContentsHeight = $('#sub').outerHeight(true)
        windowHeight = $(window).height()
        # 70 = メインコンテ下マージン
        adjustMargin = $('.gnavi').height() + 70

        $fixContents = $('.s-fix-contents')
        fixContentsHeight = $('.s-fix-contents').outerHeight(true)
        fixContentsPos = $fixContents.offset().top - adjustMargin

        fixFlg = false
        bottomFlg = false

        $(window).scroll(
          ()->
            # メインコンテンツの高さが変わった時時再度高さ適応
            currentSubHeight = $('#sub').outerHeight(true)
            currentMainContentsHeight = $('#main').outerHeight(true)

            if currentMainContentsHeight > mainContentsHeight
              $('#sub').css('min-height', currentMainContentsHeight)

            currentSubContentsHeight = $('#sub').outerHeight(true)

            # メニュー開閉等で位置が変わった時再計算
            currentFixContentsPos = $('.s-fix-contents').offset().top - adjustMargin

            if $('.s-fix-contents').not('.is-fixed,.is-bottom').length isnt 0
              #currentFixContentsPos = $('.s-fix-contents').not('.is-fixed,.is-bottom').offset().top - adjustMargin

              if currentFixContentsPos > fixContentsPos
                fixContentsPos = currentFixContentsPos

            # サブコンテンツの方が高さが低い時のみ実行
            if currentSubContentsHeight <= currentMainContentsHeight
              scrollPos = $(window).scrollTop()
              fixContentsBottomPos = $('#container').height() - (scrollPos + windowHeight) - $('#footer').outerHeight() - ((adjustMargin + fixContentsHeight) - windowHeight)

              if scrollPos > fixContentsPos
                if not fixFlg and not bottomFlg
                  $fixContents.addClass('is-fixed')
                  fixFlg = true
              else
                $fixContents.removeClass('is-fixed')
                if fixFlg
                  fixFlg = false

              if fixContentsBottomPos < 0
                $fixContents.removeClass('is-fixed')
                if fixFlg
                  fixFlg = false

                  if not bottomFlg
                    $fixContents.addClass('is-bottom')
                    bottomFlg = true
              else
                if bottomFlg
                  $fixContents.removeClass('is-bottom').addClass('is-fixed')
                  bottomFlg = false
        )

  if $('.s-fix-contents').length > 0
    $(window).on('load'
      ()->
        fixedContents()
    )

  